import { IS_DEV } from "@/constants/env";
import { IS_BETA } from "@/constants/envVersion";
import { MIGRATE_TO_CURRENT_DICTIONARY_LOCALE_MAP } from "@/lang/i18n";

export const getLanguageFullName = (dictionary, locale) => {
  try {
    const currentLocale = MIGRATE_TO_CURRENT_DICTIONARY_LOCALE_MAP[locale] || locale;
    return dictionary[currentLocale].languageCode;
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const getUniversalLanguageFullName = (dictionary) => {
  try {
    return dictionary.universal.languageCode;
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const sortLanguageCodeByFullName = (languageCodeList = [], dictionaryMap = {}) => {
  return languageCodeList.sort((a, b) => dictionaryMap[a].localeCompare(dictionaryMap[b]));
};
export const getLanguageCodeListByEnv = (
  settings = { lang: [], inputLang: [], testLang: [], betaLang: [] },
  formalListKey = "inputLang"
) => {
  const formalLanguageCodeList = formalListKey === "inputLang" ? settings.inputLang : settings.lang;
  if (IS_DEV) {
    return [...formalLanguageCodeList, ...settings.testLang];
  } else if (IS_BETA) {
    return [...formalLanguageCodeList, ...settings.betaLang];
  } else {
    return formalLanguageCodeList;
  }
};
export const getLanguageOptionList = (
  languageCodeList = [], // String[]
  dictionaryMap = {}
) => {
  const list = languageCodeList.map((language) => ({
    code: language,
    name: dictionaryMap[language] || language,
  }));
  return list;
};

export const isLanguageRightToLefMark = (languageCode) =>
  ["he-il", "ur-pk", "fa-ir", "ar-ar", "ar-jo", "ps-af"].includes(languageCode);

export const getLanguageFullEnglishName = (dictionary) => {
  try {
    return dictionary["en-us"].languageCode;
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const isLanguageSupport = ({ lang, testLang }, languageCode) => {
  let supportsLanguageList = lang;
  if (IS_DEV) {
    supportsLanguageList = [...supportsLanguageList, ...testLang];
  }
  return supportsLanguageList.includes(languageCode);
};
